













































import { Vue, Component } from "vue-property-decorator";
import firebase from "@/plugins/firebase";
import "@/types";

@Component
export default class PaymentResult extends Vue {
  get orderId() {
    return this.$route.params.orderId;
  }

  order = {
    id: "",
    uid: "",
    status: "",
    statusMessage: "",
    errorMessage: ""
  };

  async getOrder() {
    const orderRef = await firebase
      .firestore()
      .doc(`/apps/kahev-akademi/orders/${this.orderId}`)
      .get();

    if (orderRef.exists) {
      this.order.id = orderRef.id;
      this.order.uid = orderRef.data()?.uid;
      this.order.status = orderRef.data()?.status;
      this.order.statusMessage = orderRef.data()?.statusMessage;
      this.order.errorMessage = orderRef.data()?.paymentDetails.ErrMsg;

      if (this.order.status == "success") {
        if (this.userSignedIn && this.order.uid == this.user.id) {
          // Ders kayıtlarını oluştur
          this.cartItemList.map(async (item: CartItem) => {
            console.log({ uid: this.user.id, courseId: item.id });
            await this.$store.dispatch("lms/addCourseReg", {
              uid: this.user.id,
              courseId: item.id
            });
          });

          // Sepeti sil
          this.$store.dispatch("cart/deleteCart");
        }

        //TODO: Sipariş oluşturulduğunda müşteriye e-posta gönder.
      }
    }
  }

  get userSignedIn(): boolean {
    return this.$store.getters["auth/userSignedIn"];
  }

  get user(): User {
    return this.$store.getters["auth/user"];
  }

  get cartItemList(): CartItem[] {
    return this.$store.getters["cart/cartItemList"];
  }

  get cartItemCount() {
    return this.$store.getters["cart/cartItemCount"];
  }

  async mounted() {
    await this.getOrder();
  }
}
